export default {
    msg:{
        msg1:'test one',
        inputHold:'https://scholar.google.com/citations?hl=en&user=mG4imMEAAAAJ',

    },
    headerModule:{
        mainTitle:'PaperSpotlight',
        subTitle:'Every Idea Matters, Every Paper Counts', 
        navList:[
            // {name:'Home',className:'navItem',path:'/homePage',id:''},
            {name:'Pricing',className:'navItem',path:'',id:'chargeBox'},
            {name:'Contact Us',className:'navItem',path:'',id:'contactUs'},
            {name:'FAQ',className:'navItem',path:'',id:'FAQ'},
            // {name:'lang',className:'lang',path:'',id:''},
            {name:'Log in',className:'navBottomLogin',path:'/login',id:''},
            {name:'Create account',className:'navBottomReg',path:'/register',id:''},
        ],
        navList1:[
            // {name:'Home',className:'navItem',path:'/homePage',id:''},
            // {name:'Pricing',className:'navItem',path:'',id:'chargeBox'},
            // {name:'Contact Us',className:'navItem',path:'',id:'contactUs'},
            // {name:'FAQ',className:'navItem',path:'',id:'FAQ'},
            // {name:'lang',className:'lang',path:'',id:''},
            {name:'Log in',className:'navBottomLogin',path:'/login',id:''},
            {name:'Create account',className:'navBottomReg',path:'/register',id:''},
        ],
        navListLog:[
            {name:'email',className:'navBottomLogin',path:'/todo',id:''},
            {name:'Logout',className:'navBottomReg',path:'/loginOut',id:''},
        ],
        navListLog1:[
            {name:'email',className:'navBottomLogin',path:'/status',id:''},
        ],
    },
    navigate:{
        flowObj0:[
            {mainTitle:'Status',subTitle:[
                {name:'Status',index:'0-0',path:'/todo'},
                {name:'Progress',index:'0-1',path:'/progress'},
            ]},
        ],
        flowObj1:[
            {mainTitle:'Setting',subTitle:[
                {name:'Account',index:'1-1',path:'/account',icon:true},
                {name:'Google Scholar',index:'1-0',path:'/googleScholar',icon:false},
            ]},
            {mainTitle:'Preferences',subTitle:[
                {name:'My Strategy',index:'2-2',path:'/strategy',icon:false},
                {name:'My Papers',index:'2-0',path:'/papers',icon:false},
                {name:'My Plans',index:'2-1',path:'/plans',icon:false},

            ]},
        ],
        flowObj2:[
            {mainTitle:'Progress',subTitle:[
                // {name:'Overview',index:'3-0',path:'/instrumentPanel'},
                {name:'Detail',index:'3-1',path:'/progress'},
            ]},
        ],
    },
    basicInfor:{
        firstTitile:'Google Scholar is required. We will use its metrics for evaluating our work.',
        firstSubTiitle:'Google Scholar User Page (Where you can find citation numbers on the right)',
        firstBtn:'OK',
        secondTitle:'Service preference',
        secondSubTitle:'Will you allow us to search for additional information about your academic background?',
        allow:'permissible',
        notAllow:'impermissible',
        thirdTitle:'Mailbox Authorization' ,
        thirdTitle2:'Authorized Email',
        tips1:'We take privacy very seriously. This name will be used to access the signature when sending emails.',
        tips2:' If you choose not to use your own email to send. Then it will be promoted on our behalf.',
        thirdChoose1:'Send an email through your mailbox',
        thirdChoose2:'Using paperSpotlight mailboxes',
        fourTitle:'Invitation code',
        fourBtn:'verify',
        btnText1:'Save',
        resetBtn:'reprovision',
        copyBtn:'copy',
        cancelBtn:'Delete Account',
        thirdmark:'Both sides are giving away 20 emails to send and analyze',
        personInfor:[
            {title:'Name',value:'scholarName'},
            {title:'Affiliation',value:'scholarAffiliation'},
            {title:'Number of Citations',value:'citedby'},
            {title:'Inquiry Time',value:'time'},
         ],
         editPersonInfor:{
            title:'',
            oldName:'previous ',
            newName:'new ',
         }
    }, 
    articlModule:{
        articleTitle:'My Papers',
        addBtn:'Paper',
        formLable:{
            website:'Links (DOI links or article URL)',
            title:'Paper Title',
            forwardKey:'Positive Keywords',
            inverseKey:'Negative Keywords',
            operation:'Actions',
            editBtn:'Edit',
            deleteBtn:'Delete',
            link:'DOI Link, Google Scholar Link, Article URL',
            placeholder:'Enter keywords here, pressing Enter after each one',
            pdfLable:'Upload a PDF, if researchers request it. (optional)',
            datasets:'Datasets URL',
            github:'Github URL',
            celbtn:'Cancel',
            createbtn:'OK',
            confirmBtn:'OK',
        },
        createTitle:'Create Paper',
        editTitele:'Edit Paper',
        deltitle:'Are You Sure You Want to Delete This Paper?',
    },
    packageModule:{
        buytime:'Purchase Date',
        buyText:'We do not offer refunds after 7 days of ordering.',
        addArticle:'Add Papers',
        celPackage:'Cancel the package',
        pushArticle1:'The following',
        pushArticle2:'paper is boosting their Visibility',
        buyNew:'Purchase New Plan',
        buyRecord:'Purchase History/Manage Plans',
        packageAndpaper:'Active plans',
        effect:'Active',
        loseActive:'Expired',
        subActive:'Sbscription',
        subFail:'Unsubscribed',
        expired:'Expired',
        expirse:'Expires On',
        cancelSub:'Unsubscribe',
        cancelSubed:'Unsubscribed',
        refund:'Refund',
        refunded:'Refunded',
        packageDelTitle:'Are You Sure You Want to Delete This Paper?'
    },
    pushModule:{
        title:'My Strategy',
        oneText1:'Our scholars will analyze new articles in the relevant field, considering relevance and citation recommendations,considering relevance and citation recommendations, to determine which authors to prioritize for contact.',
        oneText3:'For exmaple, by default. We will prioritize sending recent articles with high relevance to your work, rather than selecting older articles with higher relevance.',
        importance:'Priorities',
        importtanceArr:[
            {name:'Time (the newer the article the higher the weight)'},
            {name:"Relevance of your paper to the author's paper (high relevance)"},
            {name:'Citation (high citation)'},
            {name:'University Ranking (high University Ranking)'},
        ],
        allowText1:'Allow Ignoring the Following Relevance',
        allowText2:'For example, both methods and applications are applicable, providing interdisciplinary support',
        
        twoResearch:'Researchers',
        twoText1:'Contact Only the Following Researchers',
        twoText2:'The following condition will apply at the same person. For example. (For the conference paper, all the authors, who is chinese Phd student, in US university will be reached.)',
        twoText3:'If there is still volume to be sent, is it permissible to send it to papers whose relevance we think is not as high as it should be? ：',
        allow:'permissible',
        notAllow:'impermissible',

        threeText1:'Exposure on social media platforms in the relevant field (only to: ticked persons. Students, PhD, Teachers, Institutes)',

        fourText1:'Are there open source codes or datasets that we should promote?',
        address:'address：',

        fiveTitle:'Suggested by reviewer',
        fiveText1:'Should we push your articles to our reviewers and editors? (To help them have a richer set of related citations when reviewing articles)',

        sixText1:'Do you agree to have your article specifically recommended to researchers who are likely to cite it in the future based on our analysis?',
        sixText2:'Would you like our editors to explore potential collaborations with other researchers? This indicates that others might interested in your paper and are considering collaboration with you.',

        sevenText1:'Would you like to serve as a reviewer? If suitable opportunities arise, we will recommend you',
        sevenText2:'Invitation to Join Our Panel of Expert Reviewers',

        eightText1:'Domains to Exclude from Email List',
        eightText2:' (Use commas to separate the emails you wish to exclude, such as those from your lab or advisor)',
        // nineText1:'Previously contacted individuals will not be contacted again.',
        nineText1:'Previously contacted individuals will not be reached out to for',
        tenText1:'Send anonymously through our organization (may reduce Citation effect)',

        saveBtn:'Save',
    },
    panelModule:{
        country:'Countires',
        emailStatus:'Mail situation',
        totalArticle:'Total Contacts',
        upUntil:'As of Today',
        perDay:'Yesterday',
        totalSender:'Total Contacts',
        receive:'Replied',
        quoted:'Citation increased',
        receiveNum:'Replied',
        citationsNum:'Citation increased',
        data:[
            { value: 40, name: 'master transmitter' },
            { value: 38, name: 'reply received' },
            { value: 32, name: 'quoted' },
        ]
    },

    mainModule:{
        firstTitle1:'Dear, PhD.students,',
        firstTitle2:'Researchers,',
        firstTitle3:'this is for you!',
        contentList:[
            {text:"If you're facing similar challenges, this will sound familiar:",className:'headerItem'},
            {text:[
                'Apply for NIW requires 50-70* citations',
                'Apply for EB1A requires 120-360* citations',
                'Apply for Assistant professor requires 150 - 800* citations',
                'Securing funding',
                'Career advancement',
                'Gaining VC investment leverage',
            ],className:'textItem'},
            {text:'erertetyt',className:'ImgItem'},
            {text:"You are not alone. <br/>  Hundreds of thousands of new graduates every year encounter the following situations:",className:'headerItem'},
            {
                text:[
                    'Limited citations / Need for more citations',
                    'Unsatisfactory citation growth',
                    'Fear of actions that might bring any downside to personal reputation',
                    'Unfamiliarity with efficiently increasing scientific reputation',
                    'No time or energy to focus on promoting their papers',
                    'Overwhelmed with research and projects',
                    'Networking and conferences take too much time, effort, and money',
                    'Applications and paperwork are overwhelming as well',
                    'Hoping citation numbers will grow on their own'
                ],className:'textItem'
            },
            {text:'We understand these challenges and are here to help you enhance your professional research reputation efficiently and effectively. ',className:'headerItem'},
            {text:'So you can continue concentrate on what you do best, and we can focus on what we do best.',className:'headerItem'},
            {text:'Allow us to assist you. ',className:'headerCenterItem'},
            {text:'Your Work, Our Spotlight – No Paper is Too Small.',className:'headerCenterItem',splitStatus:'true'},
            {text:'spotlight my papers',className:'btnItem',isevent:true},
            {text:'* The citation number required may very depends on the research area.',className:'markItem'},
        ],
        autoBtn:'Monthly Subscription',
        permonthBtn:'One-Month Purchase',
        consult:'Contact us',
        email:'Email',
        conHolder1:'Your email address for us to reply to.',
        conHolder2:'Please let us know how we can help.',
        toSay:'want to say',
        constactBtn:'Send',
        collapseObj:[
            {titel:'What is your service about?',content:'Our service helps researchers increase the visibility and citations of their academic papers through targeted promotion and strategic advice.'},
            {titel:'How does the service work?',content:'Users register on our platform, choose a service plan, submit their article information, and we handle the rest. This includes article analysis, targeted research outreach, social media promotion, and more.'},
            {titel:'Who can benefit from your services?',content:'Researchers, academics, and students looking to enhance the visibility and impact of their published work can benefit from our services.'},
            {titel:'What types of articles do you promote?',content:'We promote peer-reviewed journal articles, conference papers, and other academic publications across various disciplines.'},
            {titel:'How do you analyze articles?',content:'We have experienced editors and research experts who review and analyze the content, relevance, and impact of your article, providing targeted recommendations for citations.'},
            {titel:'What platforms do you use for promotion?',content:'We use academic social networks, relevant forums, and our networks to ensure your article reaches the right audience.'},
            {titel:'What are the different service plans you offer?',content:'We offer five plans: Basic, Basic ++, Standard, Standard++, Pro plans. Each tailored to different needs and budgets.'},
            // {titel:'How much do the services cost?',content:'Prices range from $50/month for the Basic plan to $600/month for Custom plans. Detailed pricing can be found on our Pricing page.'},
            {titel:'Can I change my plan later?',content:'You may have several plans at the same time. Feel free to cancel or purchase other plans if you wish.'},
            {titel:'How long does it take to see results?',content:'Academic citations can take 4-11 months (Preparation of Manuscript, Submission, Initial Editorial Review, Peer Review, Review Feedback, Revisions, Resubmission, Final Decision, Acceptance, Proofing, Publication) or longer to reflect in citation databases, but other metrics like article views can be tracked more quickly.'},
            {titel:'How do you ensure the promotion is ethical?',content:'We follow strict ethical guidelines to ensure our promotions are targeted and relevant, maintaining the highest standards of integrity.'},
            {titel:'Will my article definitely get more citations?',content:'While we cannot guarantee citations, our targeted promotion strategies significantly increase the likelihood of your article being noticed and cited by the right audience. According to feedback from our previous clients, the average citation growth after 6 months is 8.7 per month.'},
            {titel:'How do you handle my personal data?',content:'We take data privacy seriously and follow stringent security protocols to protect your information. Details are available in our Privacy Policy.'},
            {titel:'Is my article information secure?',content:'Yes, all article information submitted to our platform is securely stored and only used for the purpose of providing our services.'},
            {titel:'How can I contact customer support?',content:'You can reach our support team via the Contact Us page, email (contact@paperspotlight.com), for any questions or assistance.'},
            {titel:'Do you offer a trial period?',content:'No, but feel free to refund the services. see more details in refund policy.'},
            {titel:'Can I refer others to your service?',content:'We only accept clients when we are confident that we can assist them. However, please feel free to have your colleagues submit their information, and we will inform them if we are not able to help. If we find that our database lacks enough relevant papers, we will contact the client and issue a refund.'},
            {titel:'What if I am not satisfied with the service?',content:`We are committed to customer satisfaction and offer a money-back guarantee as part of our <a href="https://paperspotlight.com/paper-policy/refundPolicy.html"  target="_blank"  style="color: #FEAE00;font-family:PingFang SC"> refund policy </a>  if you are not satisfied with our services.`},
            {titel:'Can I request a custom promotion strategy?',content:'Yes, we offer custom plans where we tailor the promotion strategy based on your specific needs and goals. Please sent request email to: contact@paperspotlight.com'},
        ],
    },
    tableModule:{
        buyBtn:'Subscribe', 
        packageObj:[
            {title:'test',subTitle:'test',amount:'3',monthTotalAmount:'$3',monthAmount:'',discounts:'3',numOfPeople:'Number of people reached per month',analysis:'Analysis based on new articles in related fields',relevance:'Relevance analysis and citation suggestions',email:'Email introductions are sent to the authors of the most recent relevant articles published',anonymous:'Anonymous referral service',exposure:'Exposure on social platforms in relevant fields',push:'Push to our reviewers and editors',open:'Open source code or dataset sharing',targeted:'Targeted recommendations to authors who are likely to cite your article in the this month'},
            {title:'Basic Plan',subTitle:'Undergraduate and Graduate Students: Gradually enhance their academic background.',amount:'6.21',monthTotalAmount:'',monthAmount:'62.1',discounts:'',numOfPeople:10,analysis:true,relevance:true,email:true,anonymous:true,exposure:false,push:false,open:false,targeted:false},
            {title:'Basic ++ Plan',subTitle:'New PhD Students: Expand their network and relationships.',amount:'5.4',monthTotalAmount:'',monthAmount:'108',discounts:'',numOfPeople:20,analysis:true,relevance:true,email:true,anonymous:true,exposure:false,push:false,open:false,targeted:false},
            {title:'Standard Plan',subTitle:'PhD Candidates with 2+ Years: Preparing to apply for NIW (National Interest Waiver).',amount:'3.38',monthTotalAmount:'',monthAmount:'202.5',discounts:'',numOfPeople:60,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:false,targeted:false},
            {title:'Standard ++ Plan',subTitle:'Applicants Preparing for EB1A: Candidates preparing to apply for EB1A.',amount:'2.92',monthTotalAmount:'',monthAmount:'292.5',discounts:'',numOfPeople:100,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:false,targeted:false},
            {title:'Pro Plan',subTitle:'Academic Career Aspirants: Those preparing to join universities, seeking promotion to professor, and pursuing a career in academia.',amount:'2.25',monthTotalAmount:'',monthAmount:'450',discounts:'',numOfPeople:200,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:true,targeted:true},
        ],
        packageObj2:[
            {title:'test',subTitle:'test',amount:'3',monthTotalAmount:'3',monthAmount:'',discounts:'3',numOfPeople:'Number of people reached per month',analysis:'Analysis based on new articles in related fields',relevance:'Relevance analysis and citation suggestions',email:'Email introductions are sent to the authors of the most recent relevant articles published',anonymous:'Anonymous referral service',exposure:'Exposure on social platforms in relevant fields',push:'Push to our reviewers and editors',open:'Open source code or dataset sharing',targeted:'Targeted recommendations to authors who are likely to cite your article in the this month'},
            {title:'Basic Plan',subTitle:'Undergraduate and Graduate Students: Gradually enhance their academic background.',amount:'6.9',monthTotalAmount:'',monthAmount:'69',discounts:'',numOfPeople:10,analysis:true,relevance:true,email:true,anonymous:true,exposure:false,push:false,open:false,targeted:false},
            {title:'Basic ++ Plan',subTitle:'New PhD Students: Expand their network and relationships.',amount:'6',monthTotalAmount:'',monthAmount:'120',discounts:'',numOfPeople:20,analysis:true,relevance:true,email:true,anonymous:true,exposure:false,push:false,open:false,targeted:false},
            {title:'Standard Plan',subTitle:'PhD Candidates with 2+ Years: Preparing to apply for NIW (National Interest Waiver).',amount:'3.75',monthTotalAmount:'',monthAmount:'225',discounts:'',numOfPeople:60,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:false,targeted:false},
            {title:'Standard ++ Plan',subTitle:'Applicants Preparing for EB1A: Candidates preparing to apply for EB1A.',amount:'3.25',monthTotalAmount:'',monthAmount:'325',discounts:'',numOfPeople:100,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:false,targeted:false},
            {title:'Pro Plan',subTitle:'Academic Career Aspirants: Those preparing to join universities, seeking promotion to professor, and pursuing a career in academia.',amount:'2.50',monthTotalAmount:'',monthAmount:'500',discounts:'',numOfPeople:200,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:true,targeted:true},
        ]
    },
    //弹框提示
    boxTip:{
        basicInfor1:'Please enter a valid Google Scholar profile URL',
        basicInfor2:'Please enter a valid Google Scholar profile URL and click the OK button',
    }
}