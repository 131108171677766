<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-03-21 20:35:30
 * @FilePath: /chat_gpt/src/App.vue
-->
<template>
  <div id="app">
    <!-- <keep-alive >
      <router-view  v-if="$route.meta.keepAlive"/> 
    </keep-alive> -->
    <router-view /> 
    <div class="qrcodePanel" @mouseover="toggleHover(true)" @mouseout="toggleHover(false)">
      <dl class="qrcodePanelMain"  :class="{ hovered: isHovered }">
        <dt>
          <img :src="imgObj.qrCode" style="width: 200px;" alt="">
        </dt>
      </dl>
      <p class="qrcodePanelExtend">
        <img class="qrcodePanelExtendImg" :src="imgObj.qrCodeMin" alt="">
        <span class="qrcodePanelExtendText"> Enterprise wechat QR code </span>
        
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      imgObj:{
        qrCode:require("./assets/img/root/qrCode.png"),
        qrCodeMin:require("./assets/img/root/qrCodeMin.png"),
      },
      isHovered: false,
    }
  },
  method:{
    
  },
  methods: {
    toggleHover(hovered) {
      this.isHovered = hovered;
    },
  }
}
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#app{
  width: 100vw;
  height: 100vh;
}
.qrcodePanel{
  position: fixed;
  z-index: 99999;
  right:0;
  bottom: 200px;
  background: rgba(255,255,255,0);
  display: flex;
  .qrcodePanelMain{
    padding: 9px;
    -webkit-box-shadow: 0 0 10px #e3e4e5;
    box-shadow: 0 0 10px #e3e4e5;
    background-color: #FFFFFF;
    z-index: 1;
    display: none;
  }
  .hovered{
    display: block;
  }
  .qrcodePanelExtend{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    width: 41px;
    text-align: center;
    padding: 14px 13px;
    border-radius: 4px 0px 0px 4px;
    line-height: 16px;
   
    background: #FFFFFF;
    background: rgb(254, 174, 0);;
    -webkit-box-shadow: 2px 0 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 2px 0 15px 0 rgba(0, 0, 0, 0.15);
   
    .qrcodePanelExtendImg{
      width: 20px;
      height: 20px;
      margin-bottom: 6px;
    }
    .qrcodePanelExtendText{
      font-size: 14px;
      color: #212121;
      // color: rgb(254, 174, 0);
      writing-mode: vertical-rl; /* 文字从上到下，从右到左 */
      // transform: rotate(180deg);
    }
  }
  // .qrcodePanelExtend:before{
  //     display: block;
  //     content: " ";
  //     width: 16px;
  //     height: 16px;
  //     margin-bottom: 6px;
  //     background: url();
  //     background-image: url("./assets/img/root/qrCodeMin.png");
  //     background-size: contain
  //   }
}
@media screen and (max-width:767px) { 
  .qrcodePanel{
    display: none;
  }
}
@media screen  and (min-width: 767px) and (max-width: 1200px){
  .qrcodePanel{
    display: none;
  }
}
</style>
